import React from "react"
import { Link } from "gatsby"
import styles from "./404.module.scss"
import Layout from "../components/Layout"

const NotFound = ({ location }) => {
  return (
    <Layout location={location}>
      <main className="w100">
        <div className="sectionContainer">
          <div className={styles.notFoundWrapper}>
            <h1>Page Not Found</h1>
            <div className={styles.icon}>
              <span role="img" aria-label="Page Not Found">
                🤷‍♀️
              </span>
            </div>
            <div>페이지를 찾을 수 없습니다.</div>
            <Link to="/" className={styles.backBtn}>
              돌아가기
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default NotFound
